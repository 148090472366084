// @ts-ignore
/* eslint-disable */
import request from '../../request';
import { API } from '../../typings';

export async function getTicketingCards(
  body?: API.GetTicketingCardsParams,
  options?: { [key: string]: any },
) {
  return request<API.GetTicketingCardsResult>(`/users/ticketing/cards`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createTicketingCard(
  body: API.CreateTicketingCardParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateTicketingCardResult>(`/users/ticketing/cards`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function updateTicketingCard(
  { id, ...body }: API.UpdateTicketingCardParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateTicketingCardResult>(`/users/ticketing/cards/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function getTicketingCard(
  { id, ...body }: API.GetTicketingCardParams,
  options?: { [key: string]: any },
) {
  return request<API.GetTicketingCardResult>(`/users/ticketing/cards/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function archiveTicketingCard(
  { id, ...body }: API.ArchiveTicketingCardParams,
  options?: { [key: string]: any },
) {
  return request<API.ArchiveTicketingCardResult>(`/users/ticketing/cards/${id}/archive`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function unarchiveTicketingCard(
  { id, ...body }: API.UnarchiveTicketingCardParams,
  options?: { [key: string]: any },
) {
  return request<API.UnarchiveTicketingCardResult>(`/users/ticketing/cards/${id}/unarchive`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}
