import type { TicketingCard } from '@/declarations/ticketing';
import {
  createTicketingCard,
  getTicketingCard,
  getTicketingCards,
  updateTicketingCard,
} from '@/services/luluchat/ticketings/cards/cards';
import { useState } from 'react';
import { useRequest } from 'umi';

export default () => {
  const [ticketingCard, setTicketingCard] = useState<TicketingCard>({} as TicketingCard);
  const {
    data: ticketingCards,
    run: fetchGetTicketingCards,
    loading: isLoadingFetchGetTicketingCards,
  } = useRequest(getTicketingCards, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchGetTicketingCard, loading: isLoadingFetchGetTicketingCard } = useRequest(
    getTicketingCard,
    {
      manual: true,
      formatResult(res) {
        setTicketingCard(res?.data);
        return res?.data;
      },
    },
  );

  const { run: fetchCreateTicketingCard, loading: isLoadingFetchCreateTicketingCard } = useRequest(
    createTicketingCard,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchUpdateTicketingCard, loading: isLoadingFetchUpdateTicketingCard } = useRequest(
    updateTicketingCard,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  return {
    ticketingCard,
    fetchGetTicketingCard,
    isLoadingFetchGetTicketingCard,
    ticketingCards,
    fetchGetTicketingCards,
    isLoadingFetchGetTicketingCards,
    fetchCreateTicketingCard,
    isLoadingFetchCreateTicketingCard,
    fetchUpdateTicketingCard,
    isLoadingFetchUpdateTicketingCard,
    setTicketingCard,
  };
};
