// @ts-ignore
/* eslint-disable */
import request from '../request';
import { API } from '../typings';

export async function getTicketingStages(
  body?: API.GetTicketingStagesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetTicketingStagesResult>(`/users/ticketing/stages`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createTicketingStage(
  body: API.CreateTicketingStageParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateTicketingStageResult>(`/users/ticketing/stages`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function updateTicketingStage(
  { id, ...body }: API.UpdateTicketingStageParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateTicketingStageResult>(`/users/ticketing/stages/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function getTicketingStage(
  { id, ...body }: API.GetTicketingStageParams,
  options?: { [key: string]: any },
) {
  return request<API.GetTicketingStageResult>(`/users/ticketing/stages/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function archiveTicketingStage(
  { id, ...body }: API.ArchiveTicketingStageParams,
  options?: { [key: string]: any },
) {
  return request<API.ArchiveTicketingStageResult>(`/users/ticketing/stages/${id}/archive`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function unarchiveTicketingStage(
  { id, ...body }: API.UnarchiveTicketingStageParams,
  options?: { [key: string]: any },
) {
  return request<API.UnarchiveTicketingStageResult>(`/users/ticketing/stages/${id}/unarchive`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}
