// @ts-ignore
/* eslint-disable */
import request from '../request';
import { API } from '../typings';

export async function getTicketingTags(body?: API.GetTicketingTagsParams, options?: { [key: string]: any }) {
  return request<API.GetTicketingTagsResult>(`/users/ticketing/tags`, {
    method: 'GET',
    data: {
      sort_direction: 'desc',
      sort_field: 'priority',
      ...body,
    },
    ...(options || {}),
  });
}

export async function createTicketingTag(body: API.CreateTicketingTagParams, options?: { [key: string]: any }) {
  return request<API.CreateTicketingTagResult>(`/users/ticketing/tags`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function getTicketingTag({ id, ...body }: API.GetTicketingTagParams, options?: { [key: string]: any }) {
  return request<API.GetTicketingTagResult>(`/users/ticketing/tags/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function updateTicketingTag(
  { id, ...body }: API.UpdateTicketingTagParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateTicketingTagResult>(`/users/ticketing/tags/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function deleteTicketingTag({ id }: API.DeleteTicketingTagParams, options?: { [key: string]: any }) {
  return request<API.DeleteTicketingTagResult>(`/users/ticketing/tags/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}
